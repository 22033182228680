
























































































import { Component, Emit, Vue } from "vue-property-decorator";

import {
  querySchoolContract,
  postSimpleSchoolContract,
  postSchoolContractShared,
  querySchoolContractShared,
  getSchoolContractSharedList,
} from "@/api/school";
import { SchoolContractShared } from "@/tool/_class";
import { uuid } from "@/tool";
import * as _ from "lodash";
import dayjs from "dayjs";
@Component({
  name: "SchoolContractSharedPage",
  components: {},
})
export default class extends Vue {
  @Emit("close") private close(params: {
    schoolContractId: string;
    sharedPercent: number;
  }) {}
  private dayjs = dayjs;
  private dialogVisible: boolean = false;
  private schoolContractId: string = "";
  private ruleForm: SchoolContractShared = new SchoolContractShared();
  rules = {
    paymentDate: [
      { required: true, message: "请选择分成日期", trigger: "change" },
    ],
    sharedPercent: [
      { required: true, message: "请输入分成比例", trigger: "change" },
    ],
  };

  private tableData: SchoolContractShared[] = [];
  private title: string = "";

  private handleClose() {
    this.close({
      schoolContractId: this.schoolContractId,
      sharedPercent: _.sumBy(this.tableData, (item) => item.sharedPercent),
    });
  }

  private async submitForm() {
    await (this as any).$refs.ruleForm.validate();

    const total: number =
      _.sumBy(this.tableData, (item) => item.sharedPercent) +
      this.ruleForm.sharedPercent;
    if (total > 100) {
      this.$message({
        type: "warning",
        message: `分成总百份比(${total}%)不能超过100.`,
      });
      return;
    }
    this.ruleForm.schoolContractId = this.schoolContractId;
    const res: any = await postSchoolContractShared(this.ruleForm);

    if (res.code == 0) {
      this.$message({
        type: "success",
        message: "创建成功!",
      });
    } else {
      this.$message({
        type: "warning",
        message: res.msg,
      });
    }
    const { data: schoolContract } = await querySchoolContract(
      this.schoolContractId
    );
    schoolContract.sharedPercent = total;
    await postSimpleSchoolContract(schoolContract);
    (this as any).$refs.ruleForm.resetFields();
    this.initData();
  }

  resetForm(formName: string | number) {
    (this as any).$refs.ruleForm.resetFields();
  }

  private async initData() {
    this.ruleForm = new SchoolContractShared();
    const { data } = await getSchoolContractSharedList({
      schoolContractId: this.schoolContractId,
      pageSize: 999,
      curPage: 1,
    });
    this.tableData = data.items;
  }

  async show(schoolContractId: string) {
    this.schoolContractId = schoolContractId;
    await this.initData();
    this.dialogVisible = true;
  }

  private async __init() {}

  mounted() {
    this.__init();
  }
}
